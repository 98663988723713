import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { CodeBlock } from "../commonComponents"


export default function SearchAPIDocs() {
  const json = {
  "articles": [
    {
      "id": "https://d3v-irisagent.zendesk.com/hc/en-us/articles/360052484354-Payment-settings-for-adding-cards",
      "title": "Payment settings for adding cards",
      "url": "https://d3v-irisagent.zendesk.com/hc/en-us/articles/360052484354-Payment-settings-for-adding-cards",
      "snippet": "Follow the steps below to add a new card"
    },
    {
      "id": "https://d3v-irisagent.zendesk.com/hc/en-us/articles/360046592514-Welcome-to-your-Help-Center-",
      "title": "Welcome to your Help Center!",
      "url": "https://d3v-irisagent.zendesk.com/hc/en-us/articles/360046592514-Welcome-to-your-Help-Center-",
      "snippet": "You're looking at your new Help Center. We populated it with placeholder content to help you get started. Feel free to edit or delete this content.\nThe Help Center is designed to provide a complete self-service support option for your customers. The Help Center contains: a knowledge base and, on Guide Professional and Enterprise, a Customer Portal for support requests. You can also add a community to your Help Center if you have Zendesk Gather.\nYour customers can search for knowledge base articles to learn a task or search the community, if available, to ask fellow users questions. If your customers can't find an answer, they can submit a support request.\nFor more information, see Help Center guide for end users. Each user has a Help Center profile (Guide Professional and Enterprise), so your Help Center users can get to know one another better. Profiles contain relevant information about the user, along with their activities and contributions."
    },
    {
      "id": "https://d3v-irisagent.zendesk.com/hc/en-us/articles/360046592594-How-can-agents-leverage-knowledge-to-help-customers-",
      "title": "How can agents leverage knowledge to help customers?",
      "url": "https://d3v-irisagent.zendesk.com/hc/en-us/articles/360046592594-How-can-agents-leverage-knowledge-to-help-customers-",
      "snippet": "You can use our Knowledge Capture app to leverage your team’s collective knowledge.\nUsing the app, agents can:\nSearch the Help Center without leaving the ticket\nInsert links to relevant Help Center articles in ticket comments\nAdd inline feedback to existing articles that need updates\nCreate new articles while answering tickets using a pre-defined template\nAgents never have to leave the ticket interface to share, flag, or create knowledge, so they can help the customer, while also improving your self-service offerings for other customers.\nTo get started, see our Knowledge Capture documentation.\nAnd before your agents can start creating new knowledge directly from tickets, you’ll need to create a template for them to use. To help you along, we’ve provided some template ideas below. You can copy and paste any sample template below into a new article, add the KCTemplate label to the article, and you’ll be all set.\nQ&A template:\n[Title]\nQuestion\nwrite the question here.\nAnswer\nwrite the answer here.\nSolution template:\n[Title]\nSymptoms\nwrite the symptoms here.\nResolution\nwrite the resolution here.\nCause\nwrite the cause here.\nHow-to template:\n[Title]\nObjective\nwrite the purpose or task here.\nProcedure\nwrite the steps here."
    },
    {
      "id": "https://d3v-irisagent.zendesk.com/hc/en-us/articles/360052645754-Launching-new-Reporting-dashboard-to-GA",
      "title": "Launching new Reporting dashboard to GA",
      "url": "https://d3v-irisagent.zendesk.com/hc/en-us/articles/360052645754-Launching-new-Reporting-dashboard-to-GA",
      "snippet": "We are happy to announce the launch of our reporting dashboard to GA."
    }
  ]
}

  return (
    <Layout
      keywords="IrisAgent Search API guide"
      title="Use IrisAgent's Search API to power intelligent search in your products"
      description="Documentation on how to use IrisAgent's Search API to power intelligent, Gen-AI powered search in your products"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/search-api-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Search API Guide</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>Search API</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>

                    <h1>IrisAgent Search API Documentation</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    IrisAgent's <a href="https://irisagent.com/unified-search/">intelligent, LLM-powered search</a> can be used an API to power search on any of your frontend webpages. It can search through your internal and external knowledge articles, community forums, product documentation, and other informational content.
                    <br/>
                    <br/>
                    <h2>
                    API Guide
                    </h2>
                    <br/>
                    Below is a <span style={{"font-family": "Consolas"}}>curl</span> command to call our search API:
                    <CodeBlock language="javascript" value={`
curl --location \\
'https://api1.irisagent.com/v1/community/search/articles?text=add%20an%20account&limit=25&offset=0' \\
--header 'X-Business-Id: REPLACE_ME_BUSINESS_ID'
  `}>
</CodeBlock>
<br/>
The API inputs are as follows,
<br/><br/>
<table style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
    <tr>
        <th>Field</th>
        <th>Description</th>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>text</span></td>
        <td>[Required] URL-encoded search string</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>limit</span></td>
        <td>[Optional] Number of results to return. This is same as the page size if using this in a pagination setup. The default value is 25.</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>offset</span></td>
        <td>[Optional] The (zero-based) offset of the first item returned in the collection. The default value is 0.</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>REPLACE_ME_BUSINESS_ID</span></td>
        <td>This is the ID provided by the IrisAgent team</td>
    </tr>
</table>

<br/><br/>
A sample output of the API:
<br/><br/>
<CodeBlock language="json" value={JSON.stringify(json, null, 2)}>
</CodeBlock>

                    </div>
                    </div>
                    </section>

</Layout>
  )
}